body {
  margin: 0;
  font-family: 'Open Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 65px;
  color: #000000;
  margin-bottom: 24px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  /* or 171% */

  color: #000000;
}

nav {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20%;
  border: 1px solid rgb(216, 216, 216);
  height: 48px;
}
nav img {
  display: block;
  height: 100%;
}
nav ul {
  text-align: right;

  list-style: none;
  margin-block: 0;
  margin-inline-end: 0;
  margin-inline-end: 0;
  padding-inline-start: 0;
}
nav li a{
  color: #000;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */
  text-decoration-line: underline;
  color: #000000;
}


@media screen and (min-width: 1600px) {
  nav {
    padding: 16px 30%;
  }
}

@media screen and (max-width: 1400px) {
  nav {
    padding: 16px 10%;
  }
}

@media screen and (max-width: 1200px) {
  nav {
    padding: 16px 16px;
  }
}

@media screen and (max-width: 760px) {
  nav {
    padding: 16px 16px;
  }
}