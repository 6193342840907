.content {
  margin: 64px 20%;
  min-height: calc(100vh - 64px * 2);
  /* border: 1px solid black; */
}

.about {
  position: relative;
  /* display: grid;
  grid-template-columns: auto auto; */
  /* display: flex;
  justify-content: space-between;
  flex-flow: row;
  flex-wrap: wrap; */
}

.about .bullets {
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: auto auto;
  column-gap: 24px;
}

.about .bullets ul li {
  list-style: none;
}
/* 
.collage {
  width: 100%;
} */
.collage {
  float: right;
  width: 50%;
  margin-left: 16px;
  /* width: 300px;
  height: auto; */
}

@media screen and (min-width: 1600px) {
  .content {
    margin: 64px 30%;
  }
}

@media screen and (max-width: 1400px) {
  .content {
    margin: 64px 10%;
  }
}

@media screen and (max-width: 1200px) {
  .content {
    margin: 64px 16px;
  }
}

@media screen and (max-width: 760px) {
  .content {
    margin: 64px 16px;
  }
  .collage {
    float: none;
    display: none;
  }
}
